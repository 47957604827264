var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('eden-table-actions',{attrs:{"title":_vm.title},on:{"search":_vm.search}},[_c('template',{slot:"actions"},[_c('div',{staticClass:"ml-10 is-flex is-align-center"},[_c('el-button',{attrs:{"type":"primary","disabled":_vm.loading},on:{"click":function($event){return _vm.command({ action: 'create' })}}},[_vm._v(" Create Tag ")])],1)])],2),(_vm.loading)?_c('eden-loader'):[(_vm.pageData.length)?_c('el-table',{key:_vm.sort.property,attrs:{"table-layout":"auto","data":_vm.pageData}},[_c('el-table-column',{attrs:{"min-width":"200"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('eden-table-column-header',{attrs:{"label":'Category Name',"property":'name',"sort-property":_vm.sort.property},on:{"sort":function($event){return _vm.sortPageData($event, 'name')}}})]},proxy:true},{key:"default",fn:function(scope){return [_c('div',{staticClass:"is-flex is-align-center"},[_c('div',{staticClass:"eden-meal--image"},[(scope.row.image_url || scope.row.image_url === '')?_c('img',{staticClass:"meal--image",attrs:{"src":_vm.getThumbnailFromCloudinary(scope.row.image_url),"alt":"Image","width":"36px","height":"36px"}}):_vm._e()]),_c('router-link',{attrs:{"to":{
                name: 'tags.details',
                params: { id: scope.row.uuid },
              }}},[_c('span',{staticClass:"font-sm text-primary font-lg"},[_vm._v(" "+_vm._s(scope.row.name))])])],1)]}}],null,false,3898596435)}),_c('el-table-column',{attrs:{"min-width":"200"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('eden-table-column-header',{attrs:{"label":'Business Type',"property":'business_type',"sort-property":_vm.sort.property},on:{"sort":function($event){return _vm.sortPageData($event, 'business_type')}}})]},proxy:true},{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.business_type.name)+" ")]}}],null,false,3960599553)}),_c('el-table-column',{attrs:{"min-width":"200"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('eden-table-column-header',{attrs:{"label":'Status',"property":'status',"sort-property":_vm.sort.property},on:{"sort":function($event){return _vm.sortPageData($event, 'status')}}})]},proxy:true},{key:"default",fn:function(scope){return [_c('el-tag',{attrs:{"type":_vm.getTagType(scope.row.is_active)}},[_vm._v(" "+_vm._s(scope.row.is_active ? "Active" : "Inactive")+" ")])]}}],null,false,721477545)}),(
          _vm.allowAccessFor(['superadmin', 'admin', 'vendor_manager', 'growth'])
        )?_c('el-table-column',{attrs:{"width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-dropdown',{staticClass:"more",on:{"command":_vm.command}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"eden-icon-more"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":{
                  action: 'edit',
                  id: scope.row.uuid,
                  index: scope.$index,
                }}},[_vm._v("Edit Tag")]),_c('el-dropdown-item',{staticClass:"text-danger",attrs:{"command":{
                  action: 'delete',
                  id: scope.row.uuid,
                  index: scope.$index,
                }}},[_vm._v("Delete Tag")])],1)],1)]}}],null,false,3203768006)}):_vm._e()],1):_c('eden-content-empty',{attrs:{"text":'No Tags'}}),(_vm.pageData.length)?_c('eden-pagination',{attrs:{"from":_vm.from,"to":_vm.to,"total":_vm.total,"current-page":_vm.page,"page-size":_vm.pageSize},on:{"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event}}}):_vm._e(),_c('TagsForm',{attrs:{"action":_vm.tagAction.action,"show":_vm.tagAction.visibility,"tag":_vm.tagAction.data},on:{"update:show":function($event){return _vm.$set(_vm.tagAction, "visibility", $event)},"success":_vm.getTags}}),_c('tag-delete',{attrs:{"show":_vm.showDeleteTag,"tag-id":_vm.tagId},on:{"update:show":function($event){_vm.showDeleteTag=$event},"success":_vm.getTags}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }