<template>
  <div>
    <eden-table-actions :title="title" @search="search">
      <template slot="actions">
        <div class="ml-10 is-flex is-align-center">
          <el-button
            @click="command({ action: 'create' })"
            type="primary"
            :disabled="loading"
          >
            Create Tag
          </el-button>
        </div>
      </template>
    </eden-table-actions>

    <eden-loader v-if="loading" />

    <template v-else>
      <!-- {{ pageData }} -->
      <el-table
        table-layout="auto"
        :data="pageData"
        v-if="pageData.length"
        :key="sort.property"
      >
        <el-table-column min-width="200">
          <template #header>
            <eden-table-column-header
              :label="'Category Name'"
              :property="'name'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'name')"
            />
          </template>
          <template v-slot="scope">
            <div class="is-flex is-align-center">
              <div class="eden-meal--image">
                <img
                  v-if="scope.row.image_url || scope.row.image_url === ''"
                  :src="getThumbnailFromCloudinary(scope.row.image_url)"
                  class="meal--image"
                  alt="Image"
                  width="36px"
                  height="36px"
                />
              </div>
              <router-link
                :to="{
                  name: 'tags.details',
                  params: { id: scope.row.uuid },
                }"
              >
                <span class="font-sm text-primary font-lg">
                  {{ scope.row.name }}</span
                ></router-link
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="200">
          <template #header>
            <eden-table-column-header
              :label="'Business Type'"
              :property="'business_type'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'business_type')"
            />
          </template>
          <template v-slot="scope">
            {{ scope.row.business_type.name }}
          </template>
        </el-table-column>

        <el-table-column min-width="200">
          <template #header>
            <eden-table-column-header
              :label="'Status'"
              :property="'status'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'status')"
            />
          </template>
          <template v-slot="scope">
            <el-tag :type="getTagType(scope.row.is_active)">
              {{ scope.row.is_active ? "Active" : "Inactive" }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column
          v-if="
            allowAccessFor(['superadmin', 'admin', 'vendor_manager', 'growth'])
          "
          width="80"
        >
          <template v-slot="scope">
            <el-dropdown @command="command" class="more">
              <span class="el-dropdown-link">
                <i class="eden-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="{
                    action: 'edit',
                    id: scope.row.uuid,
                    index: scope.$index,
                  }"
                  >Edit Tag</el-dropdown-item
                >
                <el-dropdown-item
                  class="text-danger"
                  :command="{
                    action: 'delete',
                    id: scope.row.uuid,
                    index: scope.$index,
                  }"
                  >Delete Tag</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <eden-content-empty v-else :text="'No Tags'" />
      <eden-pagination
        v-if="pageData.length"
        :from="from"
        :to="to"
        :total="total"
        :current-page.sync="page"
        :page-size="pageSize"
      />
      <TagsForm
        :action="tagAction.action"
        :show.sync="tagAction.visibility"
        @success="getTags"
        :tag="tagAction.data"
      />
      <tag-delete
        :show.sync="showDeleteTag"
        :tag-id="tagId"
        @success="getTags"
      />
    </template>
  </div>
</template>

<script>
import tag from "@/requests/tags/tag";
import TagsForm from "./TagsForm.vue";
import TagDelete from "./TagDelete.vue";

export default {
  components: { TagsForm, TagDelete },

  props: {
    newTag: Object,
    tags: {
      type: Object,
      default: () => ({}), // This will not cause a linting error
    },
  },

  data() {
    return {
      tagAction: {
        action: "create",
        visibility: false,
        data: {},
      },
      loading: false,
      tagId: "",
      page: 1,
      showDeleteTag: false,
      pageData: [],
      showPagination: true,
      sort: {
        property: "name",
        direction: "asc",
      },
      pagination: "",
      showExportCustomers: false,
      pageDataTime: "",
      searchQuery: "",
      exportData: [],
    };
  },

  watch: {
    page() {
      this.getTags();
    },
  },

  computed: {
    title() {
      return this.pageData?.length + " Tags";
    },
  },

  created() {
    this.getTags();
  },

  methods: {
    getTagType(isActive) {
      return isActive ? "success" : "danger";
    },
    removeTag(id) {
      this.pageData = this.pageData.filter((tag) => tag.id !== id);
    },
    command(command) {
      this.tagId = command.id;
      switch (command.action) {
        case "create":
          this.tagAction.data = {};
          this.tagAction.action = "create";
          this.tagAction.visibility = true;
          break;
        case "edit":
          this.tagAction.data = this.pageData[command.index];
          this.tagAction.action = "edit";
          this.tagAction.visibility = true;
          break;
        case "delete":
          this.tagAction.data = this.pageData[command.index];
          this.showDeleteTag = true;
          break;
      }
    },
    sortPageData(direction, property) {
      this.sort.property = property;
      this.sort.direction = direction;
      this.pageData = this.sortList(this.pageData, property, direction);
    },
    search(query) {
      this.searchQuery = query;
      this.page = 1;
      this.getTags();
    },
    fetchTags(params) {
      tag.list(params).then(this.handleOrderResponse).catch(this.handleError);
    },
    handleOrderResponse({ data }) {
      const { status, data: tagData } = data;
      if (status) {
        this.pageData = tagData;
        this.pagination = data;
        this.from = this.pagination.meta.from;
        this.to = this.pagination.meta.to;
        this.total = this.pagination.meta.total;
        this.pageSize = this.pagination.meta.per_page;
        this.loading = false;
      }
    },
    getTags() {
      this.loading = true;
      let params = `business_type_id=${this.tags.id}&lite=1&page=${this.page}`;
      if (this.searchQuery) {
        params += `&query=${this.searchQuery}`;
      }
      this.fetchTags(params);
    },

    handleError(error) {
      this.loading = false;
      const errorMessage = error.response?.data;

      if (errorMessage.errors) {
        const errorKeys = Object.keys(errorMessage.errors);
        this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
      } else {
        this.$message.error(errorMessage.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.meal--image {
  border-radius: 8px;
  margin-right: 10px;
}
</style>
